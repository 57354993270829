import type { MenuLink } from '../domain/menu-link.interface';

export const menuTree: MenuLink[] = [
  {
    text: "Services",
    url: "",
    submenues: [
      {
        text: "Startup studio",
        url: "/software-development-for-startups/",
      },
      {
        text: "Product design and development",
        url: "/product-development-services/",
      },
      {
        text: "Team extension",
        url: "/team-extension-services/",
      },
      {
        text: "IT support",
        url: "/support-and-maintenance/",
      },
    ],
  },
  {
    text: "Expertises",
    url: "",
    submenues: [
      {
        text: "Web development",
        url: "/web-development/",
      },
      {
        text: "Mobile app development",
        url: "/mobile-development/",
      },
      {
        text: 'UI/UX design',
        url: '/ui-ux-design-services/',
      },
      {
        text: 'QA services',
        url: '/quality-assurance/',
      },
      {
        text: "Business Application Development",
        url: "/enterprise-application-development-services/",
      },
    ],
    footerText: 'Technology',
    footerTextUrl: "/technology/",
    footerSubmenues: [
      {
        text: ".NET",
        url: "/net-software-development-company/"
      },
      {
        text: "Java",
        url: "/java-development-services/"
      },
      {
        text: "React",
        url: "/reactjs-development-services/"
      },
      {
        text: "Angular",
        url: "/angular-development-services/"
      },
      {
        text: "Xamarin",
        url: "/xamarin-app-development-services/"
      },
      {
        text: "Flutter",
        url: "/flutter-app-development-services/"
      },
      {
        text: "Vue.js",
        url: "/vue-js-development-services/"
      },
    ]
  },
  {
    text: "Industries",
    url: "",
    submenues: [
      {
        text: "Healthcare",
        url: "/healthcare-software-development/",
      },
      {
        text: "Logistics",
        url: "/logistics-software-development/",
      },
      {
        text: "Finance",
        url: "/finance-software-development/",
      },
      {
        text: 'Construction',
        url: '/construction-software-development/',
      },
    ],
  },
  {
    text: "Our works",
    url: "/our-works/",
  },
  {
    text: "About us",
    url: "",
    submenues: [
      {
        text: "Who we are",
        url: "/about-us/",
      },
      {
        text: "How we work",
        url: "/approach/",
      },
      {
        text: "Our clients",
        url: "/our-clients/",
      },
      {
        text: "Our team",
        url: "/our-team/",
      },
    ],
  },
  {
    text: 'Career',
    url: '/career/'
  },
  {
    text: 'Blog',
    url: '/blog/'
  }
];