<script lang="ts">
	import type { MenuLink } from '$lib/domain/menu-link.interface';
	import { createEventDispatcher } from 'svelte';
	import { onMount } from 'svelte';

	const dispatch = createEventDispatcher();

	export let menuPosition: string;
	export let arrowPosition: string;
	export let visible: boolean;
	export let subMenus: MenuLink[] = [];
	export let footerText: string | '' = '';
	export let footerTextUrl: string | '' = '';
	export let footerSubmenues: MenuLink[] = [];
	const closeMenu = () => dispatch('closeMenu');

	onMount(() => {
			if (typeof IntersectionObserver === 'undefined') {
					return;
			}

			let observer = new IntersectionObserver(
					(entries, observer) => {
							entries.forEach((entry) => {
									if (!entry.isIntersecting) {
											return;
									}

									const htmlTarget = entry.target as HTMLImageElement;
									htmlTarget.src = htmlTarget.dataset.src || '';
									htmlTarget.classList.remove('lazyload');
									observer.unobserve(htmlTarget);
							});
					},
					{ threshold: 0, rootMargin: '20px' }
			);

			document.querySelectorAll(`.lazyload`).forEach((entry) => observer.observe(entry));
	});

	$: hidden = !visible;
	$: menuLeft = menuPosition ? menuPosition : null;
	$: arrow = arrowPosition ? arrowPosition : null;
</script>

<div class="menu flex-row" class:hidden="{hidden}" style="left: {menuLeft}px">
	<div class="menu-arrow" style="left: {arrow}px"></div>
	<div class="menu-wrapper flex-row">
		<ul class="menu-wrapper-column">
			{#each subMenus as menu}
				<li>
					<a on:click="{() => closeMenu()}" class="header-button" href="{menu.url}">
						{menu.text}
					</a>
				</li>
			{/each}
		</ul>
		
		{#if (footerText.length > 0)}
			<div>
				<div class="sub-menu-title">
					<a on:click="{() => closeMenu()}" class="header-button" href="{footerTextUrl}">
						{footerText}
					</a>
				</div>
				<ul class="menu-wrapper-row">
					{#each footerSubmenues as subMenu}
						<li>
							<a on:click="{() => closeMenu()}" class="header-button" href="{subMenu.url}">
								{subMenu.text}
							</a>
						</li>
					{/each}
				</ul>
			</div>
		{/if}
	</div>
</div>

<style lang="scss">
	@import '$styles/vars.scss';

	.hidden {
		display: none !important;
		opacity: 0;
	}

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes growDown {
		0% {
			transform: scaleY(0);
		}
		100% {
			transform: scaleY(1);
		}
	}

	.menu {
		opacity: 1;
		animation: growDown 350ms ease-in-out forwards;
		position: absolute;
		background-color: $light-grey;
		color: $main-black;
		top: 98px;
		min-width: 268px;
		max-width: 320px;
		border-radius: 12px;

		.menu-arrow {
			position: absolute;
			transform: translateX(-50%);
			top: -20px;
			width: 0;
			height: 0;
			border-left: 24px solid transparent;
			border-right: 24px solid transparent;
			border-bottom: 24px solid $light-grey;
			z-index: 0;
		}

		.menu-wrapper {
			animation: fadeInOpacity 350ms ease-in-out forwards;
			width: 100%;
			justify-content: start;
			flex-direction: column;
			padding: 24px 0;

			&-column , &-row {
				padding: 0;
				margin: 0;
				list-style: none;

				.header-button {
					color: $main-black;
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;

					&:hover {
						color: $green-accent;
					}
				}
			}

			&-column {
				li {
				  	a {
					  box-sizing: border-box;
					  display: inline-block;
					  width: 100%;
					  padding: 12px 24px;
					}
				}
			}

			&-row {
				margin: 24px 24px 0;
				display: flex;
				flex-direction: row;
				gap: 10px 24px;
				width: 100%;
				flex-wrap: wrap;
			}

			.sub-menu-title {
				padding-top: 48px;
				font-size: 16px;
				font-weight: 700;
				line-height: 22px;
				.header-button {
					color: $main-black;
					&:hover {
						color: $green-accent;
					}
				}
			  	a {
                  box-sizing: border-box;
				  display: inline-block;
				  width: 100% ;
				  padding-left: 24px;
                  padding-right: 24px;
				}
			}
		}
	}
</style>
