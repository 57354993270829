<script lang="ts">
	import {services, industries, expertises} from '$lib/config/footer';

	import ClutchIcon from '$icons/clutch-icon.svelte';
	import FacebookIcon from '$icons/facebook-icon.svelte';
	import TwitterIcon from '$icons/twitter-icon.svelte';
	import LinkedinIcon from '$icons/linkedin-icon.svelte';
	import BehanceIcon from "$icons/behance-icon.svelte";
	import Logo from "$icons/exoft-white-logo.svelte";
</script>

<footer>
	<div class="container top flex-row">

		<div class="offices flex-column">
			<div class="logo">
				<a href="/">
					<Logo />
				</a>
			</div>

			<div class="list flex-column">
					<address>
						111A T. Shevchenko Str., Lviv Ukraine
					</address>
					<a href="tel:+380639631064">+38 063 963 1064</a>
					<a href="mailto:contactus@exoft.net">contactus@exoft.net</a>
			</div>
		</div>

		<div class="site-map flex-row">
			<div class="column column-left flex-column ">
				<nav>
					<div class="header">Services</div>

					{#each services as service}
						<a href="{service.url}">{service.title}</a>
					{/each}
				</nav>


				<nav>
					<div class="header">Industries</div>

					{#each industries as industry}
						<a href="{industry.url}">{industry.title}</a>
					{/each}
				</nav>
			</div>
		</div>

		<div class="site-map flex-row">
			<div class="column flex-column">
				<nav>
					<div class="header">Expertises</div>

					{#each expertises as expertise}
						<a href="{expertise.url}">{expertise.title}</a>
					{/each}

					<div class="spacer"></div>

					<div class="header">About us</div>

					<a href="/about-us/">Who we are</a>
					<a href="/approach/">How we work</a>
					<a href="/approach/">Our clients</a>
					<a href="/approach/">Our team</a>


					<div class="spacer"></div>

					<a class="header" href="/our-works/">Our works</a>

					<a class="header" href="/blog/">Blog</a>

					<a class="header" href="/career/">Career</a>

				</nav>
			</div>
		</div>

	</div>

	<div class="container bottom">
		<div class="links flex-row">
			<div class="icons flex-row">
				<a
						href="https://www.linkedin.com/company/exoft"
						target="_blank"
						rel="noopener noreferrer nofollow">
					<LinkedinIcon />
				</a>

				<a
						href="https://twitter.com/ExoftSoftware"
						target="_blank"
						rel="noopener noreferrer nofollow">
					<TwitterIcon />
				</a>


				<a
						href="https://www.behance.net/exoft/"
						target="_blank"
						rel="noopener noreferrer nofollow">
					<BehanceIcon />
				</a>

				<a href="https://www.facebook.com/exoft" target="_blank" rel="noopener noreferrer nofollow">
					<FacebookIcon />
				</a>

				<a
						href="https://clutch.co/profile/exoft"
						target="_blank"
						rel="noopener noreferrer nofollow">
					<ClutchIcon />
				</a>
			</div>
			<div class="credentials">
				<div>
					<a href="/privacy-policy/">Privacy policy</a>
				</div>
				<div>
					© 2013-2024 Exoft. All Rights Reserved
				</div>
			</div>
		</div>
	</div>
</footer>

<style lang="scss">
	@import '$styles/vars.scss';

	footer {
		margin-top: 120px;
		color: $main-white;
		background-color: $second-black;

		.top {
			width: 100%;
			box-sizing: border-box;
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
			padding-top: 31px;
			column-gap: 10px;

			nav {
				row-gap: 12px;
				font-weight: 200;
				font-size: 14px;
				line-height: 22px;
				display: flex;
				flex-direction: column;

				.spacer {
					padding-bottom: 24px;
				}

				.header {
					font-weight: 800;
					font-size: 14px;
				}

				a {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.bottom {
			width: 100%;
			background-color: $main-black;
		}
	}

	.links {
		box-sizing: border-box;
		width: 100%;
		justify-content: space-between;
		height: 48px;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		margin-top: 24px;

		.icons {
			align-items: center;
			column-gap: 12px;

			a {
				border-radius: 6px;
				background-color: $second-black;
			}

			:global(svg) {
				padding: 6px;
				&:hover {
					fill: $green;

					:global(path) {
						fill: $green;
					}
				}
			}
		}
	}

	.credentials {
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;
		color: $main-white;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 24px;

	}

	.offices {

		.list {
			font-size: 12px;
			font-weight: 600;
			line-height: 22px;
			margin-top: 31px;
			row-gap: 12px;
			address {
				font-style: normal;
			}
		}
	}

	.column-left {
		justify-content: space-between;
	}

	@media (max-width: $portrait-phone-size) {
		footer {
			margin-top: 36px;
			.top{
				display: none;
			}
		}

		.links {
			margin-top: 0;
			height: unset;
			padding: 12px 0;
			flex-direction: column;
			align-items: start;
			row-gap: 24px;
		}
	}
</style>
