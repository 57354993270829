<script lang="ts">
	import { services, expertises, industries } from '$lib/config/footer';

	import Logo from '$lib/icons/exoft-white-logo.svelte';
	import ClutchIcon from '$icons/clutch-icon.svelte';
	import FacebookIcon from '$icons/facebook-icon.svelte';
	import TwitterIcon from '$icons/twitter-icon.svelte';
	import BehanceIcon from '../../icons/behance-icon.svelte';
	import LinkedinIcon from '$icons/linkedin-icon.svelte';

</script>

<footer>
	<div class="container top flex-row">
		<div class="offices flex-column">
			<div class="logo">
				<a href="/">
					<Logo />
				</a>
			</div>
			
			<div class="list">
				<div class="column flex-column">
					<address>
						111A T. Shevchenko Str., Lviv Ukraine
					</address>
					<a href="tel:+380639631064">+38 063 963 1064</a>
					<a href="mailto:contactus@exoft.net">contactus@exoft.net</a>
				</div>
			</div>
		</div>
		
		<nav class="services flex-column">
			<div class="header">Services</div>
			
			{#each services as service}
				<a href="{service.url}">{service.title}</a>
			{/each}
		</nav>
		
		<nav class="services flex-column">
			<div class="header">Expertises</div>
			
			{#each expertises as service}
				<a href="{service.url}">{service.title}</a>
			{/each}
		</nav>
		
		<nav class="industries flex-column">
			<div class="header">Industries</div>
			
			{#each industries as industry}
				<a href="{industry.url}">{industry.title}</a>
			{/each}
		</nav>
		
		<nav class="technologies flex-column">
			<div class="header">About us</div>
			
			<a href="/about-us/">Who we are</a>
			<a href="/approach/">How we work</a>
			<a href="/our-clients/">Our clients</a>
			<a href="/our-team/">Our team</a>
			
			<div class="spacer"></div>
		</nav>
		<nav class="technologies flex-column">
			<a class="header" href="/our-works/">Our works</a>
			<a class="header" href="/blog/">Blog</a>
			<a class="header" href="/career/">Career</a>
		</nav>
	</div>
	
	<div class="bottom">
		<div class="container links flex-row">
			<div class="icons flex-row">
				<a
					href="https://www.linkedin.com/company/exoft"
					target="_blank"
					rel="noopener noreferrer nofollow">
					<LinkedinIcon />
				</a>
				
				<a
					href="https://twitter.com/ExoftSoftware"
					target="_blank"
					rel="noopener noreferrer nofollow">
					<TwitterIcon />
				</a>
				
				<a
					href="https://www.behance.net/exoft/"
					target="_blank"
					rel="noopener noreferrer nofollow">
					<BehanceIcon />
				</a>
				
				<a href="https://www.facebook.com/exoft" target="_blank" rel="noopener noreferrer nofollow">
					<FacebookIcon />
				</a>
				
				<a
					href="https://clutch.co/profile/exoft"
					target="_blank"
					rel="noopener noreferrer nofollow">
					<ClutchIcon />
				</a>
			</div>
			<div class="credentials">
				<div>
					<a href="/privacy-policy/">Privacy policy</a>
				</div>
				<div>
					© 2013-2024 Exoft. All Rights Reserved
				</div>
			</div>
		</div>
	</div>
</footer>

<style lang="scss">
	@import '$styles/vars.scss';

	footer {
		background: $second-black;
		color: $main-white;
		margin-top: 60px;
		padding-top: 24px;
		width: 100%;

		.top {
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 30px;
			column-gap: 24px;

			nav {
				margin-top: 7px;
				row-gap: 12px;
				max-width: 20%;
				font-weight: 300;
				font-size: 14px;
				line-height: 22px;

				a {
					&:hover {
						text-decoration: underline;
					}
				}

				.header {
					font-weight: 600;
					font-size: 14px;
					line-height: 22px;
				}

				.spacer {
					padding-bottom: 24px;
				}
			}
		}

		.bottom {
			background: $main-black;
			width: 100%;
		}
	}

	.links {
		justify-content: space-between;
		height: 48px;
		align-items: center;
		margin-left: auto;
		margin-right: auto;

		.icons {
			align-items: center;
			column-gap: 12px;


			:global(svg) {
				border-radius: 6px;
				padding: 6px;
				background: $second-black;
				&:hover {
					fill: $green;

					:global(path) {
						fill: $green;
					}
				}
			}
		}

		.credentials {
			display: flex;
			column-gap: 24px;
			align-items: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 300;
			line-height: 22px;
			color: $main-white;
		}
	}

	.offices {
		padding-bottom: 48px;
		display: block;
		flex: 1 1 30%;
		max-width: 40%;
		.logo {
			margin-bottom: 32px;
		}

		.list {
			line-height: 22px;

			.column {
				flex: 1 1 50%;
				row-gap: 12px;
				font-weight: 400;
				font-size: 12px;
				line-height: 22px;
				address {
				font-style: normal;

				}
			}
		}
	}
</style>
