<script lang="ts">
	import { menuTree } from '$lib/config/menu-tree';
	import { page } from '$app/stores';
	import Logo from '$lib/icons/exoft-white-logo.svelte';
	import HeaderSubmenu from './HeaderSubmenu.svelte';
	import type { MenuLink } from '$lib/domain/menu-link.interface';

	let currentMenuItemActive: string | undefined;
	let position: string ;
	let arrowPosition: string ;

	let submenus = menuTree
		.filter((item) => !!item.submenues?.length);

	const toggleServiceMenu = (isOpen: boolean, menuItem?: MenuLink): void => {
		currentMenuItemActive = isOpen && !!menuItem ? menuItem.text : undefined;
	};

	const togglePosition = (elemEvent?):void => {
		position = elemEvent.target.getBoundingClientRect().x.toFixed(0);
		arrowPosition = (elemEvent.target.getBoundingClientRect().width / 2) + 3 + '';
	}

	const closeSubmenus = () => (currentMenuItemActive = undefined)

	$: activeMenuItem = currentMenuItemActive;
	$: menuPosition = position;
	$: hideContactUs = $page.url.pathname.includes('privacy-policy') || $page.url.pathname.includes('cookie-policy');
</script>

<header>
	<div class="container">
		<div on:mouseleave="{() => toggleServiceMenu(false)}" class="header-links-section flex-row">
			{#each submenus as submenu, i}
				<HeaderSubmenu
						menuPosition="{menuPosition}"
						arrowPosition="{arrowPosition}"
						visible="{activeMenuItem === submenu.text}"
						subMenus="{submenu.submenues}"
						footerText="{submenu.footerText}"
						footerTextUrl="{submenu.footerTextUrl}"
						footerSubmenues="{submenu.footerSubmenues}"
						on:closeMenu="{() => closeSubmenus()}" />
			{/each}

			<div class="side-section left">
				<a href="/">
					<Logo />
				</a>
			</div>

			<div class=" nav-wrap flex-row align-center">
				<nav>
					{#each menuTree as menuItem}
						{#if !menuItem?.submenues?.length}
							<div class="menu-item">
								<a
										on:mouseover="{() => toggleServiceMenu(true, menuItem)}"
										on:focus="{() => toggleServiceMenu(true, menuItem)}"
										class="{activeMenuItem === menuItem.text
										? 'anchor-button header-button active'
										: 'anchor-button header-button'}"
										href="{menuItem.url}"
								>
									{menuItem.text}
								</a>
								{#if (activeMenuItem === menuItem.text)}
									<svg class="active-circle" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
										<circle cx="3" cy="3" r="3" fill="#69B518"/>
									</svg>
								{/if}
							</div>
						{:else}
							<div class="menu-item">
								<input
										on:mouseover="{(event) => {
											togglePosition(event);
											toggleServiceMenu(true, menuItem);
										}}"
										on:focus="{() => toggleServiceMenu(true, menuItem)}"
										type="button"
										class="{activeMenuItem === menuItem.text ? 'header-button active' : 'header-button'}"
										value="{menuItem.text}"
								/>
								{#if (activeMenuItem === menuItem.text)}
									<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
										<g clip-path="url(#clip0_361_9760)">
											<path d="M5.71061 1.35608H0.289415C0.0328764 1.35608 -0.097813 1.66586 0.0861203 1.84979L2.79672 4.56039C2.90804 4.67172 3.09198 4.67172 3.20335 4.56039L5.91395 1.84979C6.09783 1.66586 5.96714 1.35608 5.71061 1.35608Z" fill="#69B518"/>
										</g>
										<defs>
											<clipPath id="clip0_361_9760">
												<rect width="6.00005" height="6" fill="white"/>
											</clipPath>
										</defs>
									</svg>
									<svg class="active-circle" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
										<circle cx="3" cy="3" r="3" fill="#69B518"/>
									</svg>
									{:else}
									<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
									<g clip-path="url(#clip0_361_9755)">
										<path d="M5.71061 1.35608H0.289415C0.0328764 1.35608 -0.097813 1.66586 0.0861203 1.84979L2.79672 4.56039C2.90804 4.67172 3.09198 4.67172 3.20335 4.56039L5.91395 1.84979C6.09783 1.66586 5.96714 1.35608 5.71061 1.35608Z"
											  fill="#BABABA"/>
									</g>
									<defs>
										<clipPath id="clip0_361_9755">
											<rect width="6.00005" height="6" fill="white"/>
										</clipPath>
									</defs>
									</svg>
								{/if}
							</div>
						{/if}
					{/each}
				</nav>

				<div class="side-section right">
					{#if !hideContactUs}
						<a type="button" class="header-button contact-us-button flex-row-center" href="/contact-us/">
							Contact Us
						</a>
					{/if}
				</div>
			</div>
		</div>
	</div>
</header>

<style lang="scss">
	@import '$styles/vars.scss';

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	header {
		background-color: $second-black;
		box-shadow: none;
		position: fixed;
		width: 100%;
		z-index: 10;
		height: 96px;
	}

	.header-links-section {
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
		height: 100%;
		align-items: center;

		.side-section {
			width: auto;

			&.left {
				display: flex;
				justify-content: flex-end;
				margin-right: 24px;
			}

			&.right {
				display: flex;
				justify-content: flex-start;
				margin-left: 48px;
			}
		}

		.nav-wrap{
			height: 100%;

			nav {
				display: flex;
				height: 100%;
				width: auto;
				justify-content: space-between;
				gap: 24px;

				.menu-item {
					position: relative;
					display: flex;
					flex-direction: row;
					align-items: center;

					input {
						padding-right: 5px;
					  padding-left: 0;
					}

					.active-circle {
						position: absolute;
						left:50%;
						bottom: 26px;
						transform: translateX(-50%);
					}
				}


				.header-button {
					height: 100%;
					font-weight: 600;
					font-style: normal;
					font-size: 16px;
					line-height: 22px;
					color: $dark-grey;
					outline: none;
					background-color: transparent;
					border: none;
					box-shadow: none;
					cursor: pointer;

					&.anchor-button {
						display: flex;
						align-items: center;
						height: 100%;
						flex-shrink: 0;
					}

					&.active {
					}

					&:hover {
						color: $main-white;
					}
				}
			}
		}

		.contact-us-button {
			font-weight: 600;
			border-radius: 24px;
			border: 2px solid $green;
			background: rgba(255, 255, 255, 0.05);
			backdrop-filter: blur(5px);
			width: 132px;
			height: 42px;

			&:hover {
				background: $green;
			}
		}
	}

	@media (max-width: $normal-desktops) {
		.header-links-section {
			.side-section {
				&.left {
					margin: 0 5px;
				}

				&.right {
					margin: 0 10px;
				}
			}
			.nav-wrap{
				nav {
					gap: 10px;
				}
			}

		}
	}


</style>
