<script lang="ts">
	import { closeAllModals } from "svelte-modals/store";
	export let isOpen = false;

	const acceptCookies = (): void => {
		localStorage.setItem('cookie-consent', 'true');
		closeAllModals();
	};
</script>

{#if isOpen}
	<article tabindex="-1" aria-hidden="true" class="root">
		<div class="consent-text">
			We use cookies to enhance your browsing experience and analyze website traffic. By continuing
			to use our website, you consent to the use of cookies. For more information, please review our <a
				href="/cookie-policy/"
				target="_blank"
				class="inline-link">Cookie Policy.</a>
		</div>

		<button type="button" class="action-button" on:click="{acceptCookies}" data-bs-dismiss="modal">
			Got it
		</button>
	</article>
{/if}

<style lang="scss">
	@import '$styles/vars.scss';

	article {
		display: flex;
		position: fixed;
		bottom: 1em;
		top: auto;
		right: 0;
		left: 0;
		flex-direction: row;
		padding: 48px;
		margin-left: 1rem ;
		margin-right: 1rem;
		background-color: $light-grey;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		z-index: 200;
		width: fit-content;
		height: fit-content;
		align-items: center;
		color: $main-black;
		max-width: 1100px;
		column-gap: 80px;
		box-sizing: border-box;

		.consent-text {
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			margin: 0;
			color: $main-black;
		}

		.action-button {
			color: $main-black;
			cursor: pointer;
			white-space: nowrap;
		}
	}

	@media (max-width: $portrait-phone-size) {
		article {
			max-width: 94%;
			flex-direction: column;
			row-gap: 24px;
			padding: 24px;
			margin-left: 3%;
		}
	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
		article {
			max-width: 94%;
			margin-left: 3%;
		}
	}
</style>
