export const services: { title: string, url: string }[] = [
  {
    title: 'Startup studio',
    url: '/software-development-for-startups/'
  },
  {
    title: 'Product design and development',
    url: '/product-development-services/'
  },
  {
    title: 'Team extension',
    url: '/team-extension-services/'
  },
  {
    title: 'IT support',
    url: '/support-and-maintenance/'
  }
];

export const expertises: { title: string, url: string }[] = [
  {
    title: 'Web development',
    url: '/web-development/'
  },
  {
    title: 'Mobile app development',
    url: '/mobile-development/'
  },
  {
    title: 'UI/UX design',
    url: '/ui-ux-design-services/'
  },
  {
    title: 'QA services',
    url: '/quality-assurance/',
  },
  {
    title: 'Business Application Development',
    url: '/enterprise-application-development-services/'
  },
];

export const industries: { title: string, url: string }[] = [
  {
    title: 'Healthcare',
    url: '/healthcare-software-development/'
  },
  {
    title: 'Logistics',
    url: '/logistics-software-development/'
  },
  {
    title: 'Finance',
    url: '/finance-software-development/'
  },
  {
    title: 'Construction',
    url: '/construction-software-development/'
  }
]

export const technologies: { title: string, url: string }[] = [
  {
    title: '.net',
    url: '/net-software-development-company/'
  },
  {
    title: 'Java',
    url: '/java-development-services/'
  },
  {
    title: 'reactJS',
    url: '/reactjs-development-services/'
  },
  {
    title: 'Angular',
    url: '/angular-development-services/'
  },
  {
    title: 'Xamarin',
    url: '/xamarin-app-development-services/'
  },
  {
    title: 'Flutter',
    url: '/flutter-app-development-services/'
  },
  {
    title: 'Vue.js',
    url: '/vue-js-development-services/'
  },
]